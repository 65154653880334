import Features from "./componets/Features";
import Header from "./componets/Header";
import MainHero from "./componets/MainHero";
import MainHeroImage from "./componets/MainHeroImage";
import Komitet from "./componets/Komitet";
import Footer from "./componets/Footer";
import Ja from "./componets/Ja";

function App() {
  return (
    <div className={`bg-background grid  overflow-hidden`}>
      <div className={`relative bg-background`}>
        <div className="mx-auto max-w-7xl">
          <div
            className={`relative z-10 pb-8 bg-white text-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32`}
          >
            <Header />
            <MainHero />
          </div>
        </div>
        <MainHeroImage />
      </div>
      <div>
        <div className="w-full h-8 bg-[#f5df18]"></div>
        <div className="w-full h-8 bg-[#15631b]"></div>
      </div>
      <Features />
      <Komitet />
      <Footer />
      <Ja />
    </div>
  );
}

export default App;
