import React from "react";
import { Link } from "react-scroll";

const MainHero = () => {
  return (
    <main className="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
      <div className="sm:text-center lg:text-left">
        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline">
            Komitet <span className="text-[#15631b]">Wyborczy</span>
          </span>{" "}
          <span className="block text-4xl xl:inline ">
            Wyborców Wspólnota Powiatowa
          </span>
        </h1>
        <p className="mt-3 text-base font-semibold text-blue-400 sm:mt-5 sm:text-xl sm:max-w-xl sm:mx-auto md:mt-5 md:text-2xl lg:mx-0">
          "ZNACIE NAS MY NIE OBIECUJEMY <br /> MY REALIZUJEMY"
        </p>
      </div>
    </main>
  );
};

export default MainHero;
