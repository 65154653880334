import React from "react";

const Komitet = () => {
  return (
    <section
      className={`relative bg-background py-12 bg-gradient-to-r from-yellow-300 to-green-700 mt-[100px]`}
      id="komitet"
    >
      <div className={`container mx-auto px-2 pt-4 pb-12 text-primary  `}>
        <h1
          className={`w-full my-2 text-5xl font-bold leading-tight text-center text-black`}
        >
          Kandydaci Którym Można Zaufać
        </h1>
        <div className={`w-full mb-4`}>
          <div
            className={`h-1 mx-auto bg-[#15631b] w-[300px] opacity-25 my-0 py-0 rounded-t`}
          ></div>
        </div>
        <div className="grid grid-cols-2 gap-3 lg:grid-cols-4">
          <div className="flex flex-col items-center gap-2 p-2 text-center bg-white rounded-md">
            <div className="text-blue-700 font-medium text-[18px]">
              <h1>Okręg wyborczy nr 1 </h1>
              <h2>Miasto Złotoryja</h2>
            </div>
            <ol className="text-black">
              <li>Aleksander Ciempka</li>
              <li>Marcin Szarata</li>
              <li>Marzena Hajdun</li>
              <li>Robert Kiełbasa</li>
              <li>Edyta Tusińska</li>
              <li>Beata Chudzińska</li>
              <li>Agnieszka Gralak-Słonina</li>
            </ol>
          </div>
          <div className="flex flex-col items-center gap-2 p-2 text-center bg-white rounded-md">
            <div className="text-blue-700 font-medium text-[18px]">
              <h1>Okręg wyborczy nr 2 </h1>
              <h2>Gmina Złotoryja</h2>
            </div>
            <ol className="text-black">
              <li>Jacek Dębski</li>
              <li>Agnieszka Łucka</li>
              <li>Piotr Warda</li>
              <li>Michał Kmita</li>
              <li>Katarzyna Kądzielska</li>
            </ol>
          </div>
          <div className="flex flex-col items-center gap-2 p-2 text-center bg-white rounded-md">
            <div className="text-blue-700 font-medium text-[18px]">
              <h1>Okręg wyborczy nr 3 </h1>
              <h2>Miasto i Gmina Świerzawa, Miasto Wojcieszów</h2>
            </div>
            <ol className="text-black">
              <li>Igor Brzezicki</li>
              <li>Joanna Jędrychowska</li>
              <li>Adrian Raczyło</li>
              <li>Ewelina Domańska</li>
              <li>Elżbieta Tusińska</li>
              <li>Jan Lis</li>
            </ol>
          </div>
          <div className="flex flex-col items-center gap-2 p-2 text-center bg-white rounded-md">
            <div className="text-blue-700 font-medium text-[18px]">
              <h1>Okręg wyborczy nr 4 </h1>
              <h2>Gmina Zagrodno, Gmina Pielgrzymka</h2>
            </div>
            <ol className="text-black">
              <li>Robert Porębski</li>
              <li>Ambroży Joanna</li>
              <li>Piędel Karol</li>
              <li>Dariusz Wolski </li>
              <li>Rozalia Nowaczewska </li>
            </ol>
          </div>
        </div>
        <div className="mt-5 text-center">
          <h1 className="text-xl">
            Informacje o komitecie:{" "}
            <a
              href="https://wybory.gov.pl/samorzad2024/pl/komitet/38709?elections=rada_powiatu"
              className="text-blue-800 hover:underline"
            >
              Zobacz Tu.
            </a>
          </h1>
          <div className="text-center mt-[10px]">
            <h1>Rejest wpłat - stan 0 zł</h1>
            <h1>Rejest zaciągnientych kredytów - stan 0 zł</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Komitet;
