import React from "react";

const Footer = () => {
  return (
    <div className="flex flex-col items-center justify-center px-2 pb-12 mx-auto mt-6">
      <h1 className="text-3xl font-bold text-red-700 uppercase">Pamiętaj!</h1>
      <div className="text-center mt-[50px] p-4 border-4 border-red-700 ">
        <p className="text-xl text-black">
          W tych wyborach głosujemy na{" "}
          <span className="font-bold text-red-700">KANDYDATA.</span> Nie tyle
          istotna jest lista co człowiek, na którego oddasz głos. To ty
          decydujesz kto będzie radnym.
        </p>
      </div>
    </div>
  );
};

export default Footer;
