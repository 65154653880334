import React from "react";
import { CiHospital1 } from "react-icons/ci";
import { IoSchoolOutline } from "react-icons/io5";
import { MdOutlineAddRoad } from "react-icons/md";
import { MdOutlineSportsVolleyball } from "react-icons/md";

const Features = () => {
  return (
    <div className={`py-12 bg-background mt-[50px] `} id="cele">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl">
            NASZE NAJWAŻNIEJSZE <span className="text-[#f5df18]">CELE</span>
          </p>
          <p className="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">
            Nasze plany na rozwój:
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="relative">
              <dt>
                <div
                  className={`absolute flex items-center justify-center h-12 w-12 rounded-md bg-background text-tertiary border-blue-400 border-4`}
                >
                  <CiHospital1 />
                </div>
                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                  SZPITAL POWIATOWY
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Profesjonalny, wielooddziałowy - dostępny dla każdego
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div
                  className={`absolute flex items-center justify-center h-12 w-12 rounded-md bg-background text-tertiary border-blue-400 border-4`}
                >
                  <IoSchoolOutline />
                </div>
                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                  PRZYJAZNE SZKOŁY
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Nowoczesne szkoły, zachęcające do rozwoju, wykorzystujące w
                nauczaniu najnowsze metody i technologie informatyczne
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div
                  className={`absolute flex items-center justify-center h-12 w-12 rounded-md bg-background text-tertiary border-blue-400 border-4`}
                >
                  <MdOutlineAddRoad />
                </div>
                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                  DROGI
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Poprawa infrastruktury drogowej naszego powiatu
              </dd>
            </div>
            <div className="relative">
              <dt>
                <div
                  className={`absolute flex items-center justify-center h-12 w-12 rounded-md bg-background text-tertiary border-blue-400 border-4`}
                >
                  <MdOutlineSportsVolleyball />
                </div>
                <p className="ml-16 text-lg font-medium leading-6 text-gray-900">
                  SPORT I REKREACJA
                </p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Wsparcie i rozwój aktywności fizycznej mieszkańców powiatu
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Features;
