import React from "react";

const Ja = () => {
  return (
    <div className="w-full h-[40px] bg-gray-100 flex justify-center items-center mt-[50px]">
      <h1 className="font-semibold text-black text-md">
        Designed by{" "}
        <a href="https://github.com/jakubSerok" className="underline ">
          Kubinii
        </a>{" "}
        2024
      </h1>
    </div>
  );
};

export default Ja;
