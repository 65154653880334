import React from "react";
import bg from "../assets/bg.jpg";

const MainHeroImage = () => {
  return (
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        className="object-cover w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full"
        src={bg}
        alt="happy team image"
      />
    </div>
  );
};

export default MainHeroImage;
